export default {
  convertTime(input) {
    let date;
    if (input.includes('T')) {
      date = new Date(input);
    } else {
      date = new Date(parseInt(input));
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  },
  createObjectId() {
    let timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
    return (
      timestamp +
      'xxxxxxxxxxxxxxxx'
        .replace(/[x]/g, function () {
          return ((Math.random() * 16) | 0).toString(16)
        })
        .toLowerCase()
    )
  },

  currencyStringToNumber(currency) {
    return Number(currency.replace(/[^0-9\.-]+/g, ''))
  },

  convertCurrency(currency) {
    return currency.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  },

  convertNumber(text) {
    return text.toLocaleString(undefined, { maximumFractionDigits: 2 })
  },

  compare(a, b) {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  },

  generateVoucherCode() {
    let chars = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let serialLength = 8

    return Array.from(crypto.getRandomValues(new Uint32Array(serialLength)))
      .map((x) => chars[x % chars.length])
      .join('')
  },

  generateSerialNumber() {
    let len = 10
    let wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

    return Array.from(crypto.getRandomValues(new Uint32Array(len)))
      .map((x) => wishlist[x % wishlist.length])
      .join('')
  },

  generateNumber() {
    let chars = '1234567890'
    let serialLength = 7

    return Array.from(crypto.getRandomValues(new Uint32Array(serialLength)))
      .map((x) => chars[x % chars.length])
      .join('')
  },

  getImageURL(url, size) {
    let domain = 'https://storage.googleapis.com/';

    // Check if the URL does not start with the expected domain
    if (!url.startsWith('https://firebasestorage')) {
      return url; // Return the original URL
    }

    // Format url
    let convertedUrl = decodeURIComponent(url);

    // Clean path
    let imagePath = convertedUrl.substring(convertedUrl.indexOf('/b/')).split('?')[0];

    // Clean file type
    let fileType = imagePath.substring(imagePath.lastIndexOf('.') + 1);
    imagePath = imagePath.replace('.' + fileType, "").replace("/b/", "").replace('/o', "");

    return domain + imagePath + '_' + size + 'x' + size + '.webp';
  },


  generateColor(indexColor) {
    switch (indexColor) {
      case 0:
        return 'rgba(250, 80, 64, 1)'
      case 1:
        return 'rgba(41, 178, 137, 1)'
      case 2:
        return 'rgba(84, 0, 116, 1)'
      case 3:
        return 'rgba(224, 111, 78, 1)'
      case 4:
        return 'rgba(179, 169, 175, 1)'
      case 5:
        return 'rgba(240, 166, 214, 1)'
      case 6:
        return 'rgba(206, 11, 44, 1)'
      case 7:
        return 'rgba(117, 50, 67, 1)'
      case 8:
        return 'rgba(244, 180, 0, 1)'
      case 9:
        return 'rgba(41, 180, 107, 1)'
      case 10:
        return 'rgba(163, 115, 97, 1)'
      case 11:
        return 'rgba(244, 175, 90, 1)'
      case 12:
        return 'rgba(109, 204, 147, 1)'
      case 13:
        return 'rgba(77, 164, 107, 1)'
      case 14:
        return 'rgba(144, 206, 93, 1)'
      case 15:
        return 'rgba(188, 218, 122, 1)'
      case 16:
        return 'rgba(249, 232, 145, 1)'
      case 17:
        return 'rgba(224, 209, 118, 1)'
      case 18:
        return 'rgba(163, 222, 194, 1)'
      case 19:
        return 'rgba(172, 223, 230, 1)'
      case 20:
        return 'rgba(166, 197, 228, 1)'
      case 21:
        return 'rgba(85, 135, 224, 1)'
      case 22:
        return 'rgba(153, 158, 248, 1)'
      case 23:
        return 'rgba(179, 157, 248, 1)'
      case 24:
        return 'rgba(143, 143, 143, 1)'
      case 25:
        return 'rgba(200, 189, 191, 1)'
      case 26:
        return 'rgba(198, 168, 170, 1)'
      case 27:
        return 'rgba(233, 150, 177, 1)'
      case 28:
        return 'rgba(193, 123, 224, 1)'
      case 29:
        return 'rgba(157, 126, 220, 1)'
      default:
        return 'rgba(41, 178, 137, 1)'
    }
  },
}
